import React from "react";
import { Table } from "semantic-ui-react";
// Components
import {
    ApprovalCell,
    ApprovalCellNonProfit,
    ConfirmationPopup,
    DenyCell,
    EditableCell,
    ItemCell,
    MarkCompletedCell,
    MiscCell,
    RadioCell,
    UserCell
} from "../atoms/Table";
import { MessageModal } from "../organisms/Modal";
import { CheckBox } from "../atoms/Images";
// Constants
import i18nstring, { strings } from "../../i18n";
import { ItemCategory, ItemState } from "../../Shared/Constants/Item";
// Theme
import "./MoleculeStyles.scss";
import { Roles } from "../../Shared/Constants/Roles";
import ZipTransferPopup  from "../organisms/ZipTransferPopup";

export function TableHeadersMap({ headers }) {
  return headers.map((header, index) => {
    const widthStyle = {
      minWidth:
        index === 0
          ? "175px"
          : `${header.length > 9 ? 130 + header.length : 80}px`
    };
    return (
      <div className="table_header_cell" style={widthStyle} key={index}>
        {header}
      </div>
    );
  });
}

export function TableHeaders({ isChecked, checkAll, groupActions, headers }) {
  return (
    <div className="table_header">
      {groupActions ? (
        <CheckBox
          isChecked={isChecked}
          toggleCheckBox={() => checkAll(!isChecked)}
        />
      ) : null}
      <TableHeadersMap headers={headers} />
      {groupActions ? groupActions() : null}
    </div>
  );
}

export function HeaderCell(props) {
  const { headers } = props;
  return (
    <Table.Header>
      <Table.Row>
        <HeaderMap headers={headers} />
      </Table.Row>
    </Table.Header>
  );
}

function HeaderMap({ headers }) {
  return headers.map((header, index) => (
    <Table.HeaderCell key={index}>{header}</Table.HeaderCell>
  ));
}

function ActCell({
  changeApproval,
  isApproved,
  sendEmail,
  userEmail,
  userGroup,
  userId
}) {
  return (
    <div className="table_row_action">
      <ApprovalCell
        changeApproval={changeApproval}
        isApproved={isApproved}
        userGroup={userGroup}
        userId={userId}
      />
      <DenyCell
        changeApproval={changeApproval}
        userGroup={userGroup}
        userId={userId}
      />
      <MessageModal
        userId={userId}
        sendEmail={sendEmail}
        userEmail={userEmail}
      />
    </div>
  );
}

function NonProfitActCell({
  changeApproval,
  impersonate,
  isApproved,
  sendEmail,
  userEmail,
  userGroup,
  userId,
  users,
}) {
  return (
    <div className="table_row_action">
      <ApprovalCellNonProfit
        changeApproval={changeApproval}
        impersonate={impersonate}
        isApproved={isApproved}
        userGroup={userGroup}
        userId={userId}
      />
      <ConfirmationPopup
        changeApproval={changeApproval}
        userGroup={userGroup}
        userId={userId}
      />

        < ZipTransferPopup
        users={users}
        userId={userId}
        />
      <MessageModal
        userId={userId}
        sendEmail={sendEmail}
        userEmail={userEmail}
      />
    </div>
  );
}

export function SelfServiceRow(props) {
  const {
    attemptClaimJob,
    item,
    attemptMarkAsDelivered,
    activeRowIndex,
    onRowClick,
    index
  } = props;
  if (!item) {
    return null;
  }
  const selectStatus = newStatus => {
    switch (newStatus) {
      case ItemState.CLAIMED:
        attemptClaimJob(item.id);
        break;
      case ItemState.DELIVERED:
        attemptMarkAsDelivered(item.id);
        break;
      default:
        console.warn("unhandled status");
        break;
    }
  };
  const currentStatus = item ? item.state : "";
  const isDelivered = currentStatus === ItemState.DELIVERED;
  const isClaimed = currentStatus === ItemState.CLAIMED || isDelivered;
  const markCompletedOptions = [
    {
      label: isDelivered ? "Delivered" : "Mark as completed",
      action: ItemState.DELIVERED,
      active: currentStatus === ItemState.DELIVERED,
      unavailable: false
    }
  ];
  const dateConvert = item.dateScheduled ? new Date(item.dateScheduled) : new Date();
  const dateFormatted = `${
    dateConvert.getMonth() + 1
  }/${dateConvert.getDate()}/${dateConvert.getFullYear()}`;
  return (
    <div key={item.id}>
      <div
        className="table_row"
        style={Object.assign(
          {},
          { cursor: "pointer" },
          activeRowIndex == index ? { border: "none" } : {}
        )}
        onClick={() => onRowClick(activeRowIndex == index ? -1 : index)}
      >
        <ItemCell item={item} />
        <MiscCell label={item.category} />
        <MiscCell label={item.producerContact.name} />
        <MiscCell label={dateFormatted} />
        {item.category == ItemCategory.DROPOFF ? (
          <>
            <MarkCompletedCell
              options={markCompletedOptions}
              selectStatus={selectStatus}
              currentStatus={ItemState[item.state]}
            />
          </>
        ) : (
          <>
            <MiscCell label={""} />
          </>
        )}
      </div>
      {activeRowIndex == index && (
        <div className="expanded-table-row">
          <div className="expanded-table-row-col">
            <h4>Gardener Details</h4>
            <div>{item?.producerContact?.name}</div>
            <div>{item?.producerContact?.email}</div>
            <div>{item?.producerContact?.phoneNumber}</div>
            <div>{item?.producerContact?.zip}</div>
            <div>{item?.producerContact?.streetAddress1}</div>
            <div>{item?.producerContact?.instructions}</div>
          </div>

          {(item?.courierContact != null && item.category !== ItemCategory.DROPOFF ) && (
            <div className="expanded-table-row-col-margin">
              <h4>Courier Details</h4>
              <div>{item?.courierContact?.name}</div>
              <div>{item?.courierContact?.email}</div>
              <div>{item?.courierContact?.phoneNumber}</div>
              <div>{item?.courierContact?.zip}</div>
              <div>{item?.courierContact?.streetAddress1}</div>
              <div>{item?.courierContact?.instructions}</div>
            </div>
          )}
          {item.category == ItemCategory.DROPOFF && (
            <div className="expanded-table-row-col-margin">
              <h4>Location Details</h4>
              <div>{item?.recipientContact?.streetAddress1}</div>
            </div>
          )}
          <div className="expanded-table-row-col-margin">
            <h4>Item Details</h4>
            <div className="expanded-table-row-col-margin-social">Social Picture Sharing:&nbsp;<h5>{item?.socialPictureSharing ? "Allowed" : "Not Allowed"}</h5><a className="expanded-table-row-col-margin-social-link" target="_blank" href={item?.photoURL}>View</a></div>
            <div>{item?.weight ? item?.weight + " Pounds" : ""}</div>
            <div>{item?.notes}</div>
          </div>
          {(item.state === ItemState.REJECTED) && (
            <div className="expanded-table-row-col-margin">
              <h4>{i18nstring(strings.REJECTION_REASON)}</h4>
              <div>{item?.rejectedReason}</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export function FoodWeightRow(props) {
  const { handleUpdateWeights, updatedWeights, item } = props;
  const courierContact = item.courierContact
    ? item.courierContact.name
    : "None";
  const dateEnteredConvert = new Date(item.datePosted);
  const dateDeliveredConvert = new Date(item.dateDelivered);
  const dateEnteredFormatted = `${
    dateEnteredConvert.getMonth() + 1
  }/${dateEnteredConvert.getDate()}/${dateEnteredConvert.getFullYear()}`;
  const dateDeliveredFormatted = `${
    dateDeliveredConvert.getMonth() + 1
  }/${dateDeliveredConvert.getDate()}/${dateDeliveredConvert.getFullYear()}`;
  return (
    <div className="table_row">
      <ItemCell item={item} />
      <MiscCell label={item.category} />
      <MiscCell label={strings[item.state]} />
      <MiscCell label={item.producerContact.name} />
      <MiscCell label={courierContact} />
      <MiscCell label={dateEnteredFormatted} />
      <MiscCell label={dateDeliveredFormatted} />
      <EditableCell
        item={item}
        weight={item.weight}
        updatedWeights={updatedWeights}
        handleUpdateWeights={handleUpdateWeights}
      />
    </div>
  );
}

export function CourierRow({
  changeApproval,
  user,
  sendEmail,
  toggleMore,
  selectUser,
  isChecked
}) {
  const isApproved = user.approvalStatus === i18nstring(strings.APPROVED);
  return (
    <div className="table_row">
      <UserCell user={user} selectUser={selectUser} isSelected={isChecked} />
      <MiscCell label={user.zip} />
      <MiscCell label={user.email} />
      <ActCell
        changeApproval={changeApproval}
        isApproved={isApproved}
        userId={user.id}
        userEmail={user.email}
        userGroup={Roles.COURIER}
        toggleMore={toggleMore}
        sendEmail={sendEmail}
      />
    </div>
  );
}

export function GardenerRow({ changeApproval, user, sendEmail, toggleMore }) {
  const isApproved = user.approvalStatus === i18nstring(strings.APPROVED);
  return (
    <div className="table_row">
      <UserCell user={user} />
      <MiscCell label={user.zip} />
      <MiscCell label={user.email} />
      <ActCell
        changeApproval={changeApproval}
        isApproved={isApproved}
        userId={user.id}
        userEmail={user.email}
        userGroup={Roles.COURIER}
        toggleMore={toggleMore}
        sendEmail={sendEmail}
      />
    </div>
  );
}

export function NonProfitRow({
  changeApproval,
  user,
  sendEmail,
  toggleMore,
  selectUser,
  isChecked,
  impersonate,
  users
}) {
  const isApproved = user.approvalStatus === i18nstring(strings.APPROVED);
  return (
    <div className="table_row">
      <UserCell user={user} selectUser={selectUser} isSelected={isChecked} />
      <MiscCell label={user.zip} />
      <MiscCell label={user.email} />
      <NonProfitActCell
        changeApproval={changeApproval}
        impersonate={impersonate}
        isApproved={isApproved}
        userId={user.id}
        userEmail={user.email}
        userGroup={Roles.COURIER}
        toggleMore={toggleMore}
        sendEmail={sendEmail}
        users={users}
      />
    </div>
  );
}
