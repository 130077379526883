import React from "react";
import { gardenersHeaders } from "../../model/Table";
// Components
import {
  CourierRow,
  FoodWeightRow,
  GardenerRow,
  NonProfitRow,
  SelfServiceRow,
  TableHeaders
} from "../molecules/Table";
import { GroupMessageModal } from "./Modal";

// Constants
import i18nstring, { strings } from "../../i18n";
import "./OrganismStyles.scss";
import { ItemState } from "../../Shared/Constants/Item";

function GroupAction({ approved, selectedUsers, changeApproval }) {
  const allSelected = selectedUsers["APPROVED"] || selectedUsers["PENDING"];

  const approveUser = event => {
    if (event) {
      event.preventDefault();
    }

    let usersToChange = Object.keys(selectedUsers).filter(
      key => selectedUsers[key]
    );
    if (allSelected) {
      usersToChange = Object.keys(selectedUsers).filter(
        userId => userId !== "PENDING" && userId !== "APPROVED"
      );
      changeApproval(i18nstring(strings.APPROVED), usersToChange);
    } else {
      changeApproval(i18nstring(strings.APPROVED), usersToChange);
    }
  };
  const denyUser = event => {
    if (event) {
      event.preventDefault();
    }
    let usersToChange = Object.keys(selectedUsers).filter(
      key => selectedUsers[key]
    );
    if (allSelected) {
      usersToChange = Object.keys(selectedUsers).filter(
        userId => userId !== "PENDING" && userId !== "APPROVED"
      );
      changeApproval(i18nstring(strings.PENDING), usersToChange);
    } else {
      changeApproval(i18nstring(strings.PENDING), usersToChange);
    }
  };
  return approved === "APPROVED" ? (
    <div className="table_header_action_button_deny" onClick={denyUser}>
      Deny
    </div>
  ) : (
    <div className="table_header_action_button" onClick={approveUser}>
      Approve
    </div>
  );
}

export function CourierTable({
  approvalStatus,
  headers,
  changeApproval,
  users,
  selectUser,
  selectedUsers,
  sendEmail,
  sendEmailMultiple,
  sendEmailToGroup,
  userGroup,
  toggleMore
}) {
  const checkAll = () => selectUser(approvalStatus);
  const oneOrMoreSelected =
    Object.values(selectedUsers).filter(val => val).length > 0;
  const groupActions = () =>
    oneOrMoreSelected ? (
      <div className="table_header_cell_group">
        <div className="table_header_group_action_cell">
          <GroupAction
            users={selectedUsers}
            approved={approvalStatus}
            actionType={i18nstring(strings.APPROVED)}
            selectedUsers={selectedUsers}
            changeApproval={changeApproval}
          />
        </div>
        <div className="table_header_group_action_cell">
          <GroupMessageModal
            selectedUsers={selectedUsers}
            sendEmailMultiple={sendEmailMultiple}
            sendEmailToGroup={sendEmailToGroup}
            groupName={userGroup}
          />
        </div>
      </div>
    ) : (
      <div className="table_header_cell">Action</div>
    );
  return (
    <div className="table">
      <TableHeaders
        groupActions={groupActions}
        isChecked={selectedUsers[approvalStatus]}
        checkAll={checkAll}
        headers={headers}
      />
      <div>
        {users.map((user, index) => {
          const toggleUser = () => {
            selectUser(user.id);
          };
          const isSelectedUser =
            selectedUsers[user.id] || selectedUsers[approvalStatus];
          return (
            <CourierRow
              key={index}
              user={user}
              changeApproval={changeApproval}
              toggleMore={toggleMore}
              sendEmail={sendEmail}
              isChecked={isSelectedUser}
              selectUser={toggleUser}
              selectedUsers={selectedUsers}
            />
          );
        })}
      </div>
    </div>
  );
}

export function SelfServiceTable(props) {
  const exampleRow = [
    {
      name: "(Example item)",
      category: "-",
      producerContact: { name: "-" },
      datePosted: new Date()
    }
  ];
  const itemsRow = props.items.length ? props.items : exampleRow;

  return (
    <div className="table table_food_weight">
      <TableHeaders headers={props.headers} />
      <div>
        {itemsRow.map((item, index) => {
          return (
            <SelfServiceRow
              onRowClick={props.onRowClick}
              activeRowIndex={props.activeRowIndex}
              key={index}
              index={index}
              item={item}
              userId={props.userId}
              attemptClaimJob={props.attemptClaimJob}
              attemptMarkAsDelivered={props.attemptMarkAsDelivered}
            />
          );
        })}
      </div>
    </div>
  );
}

export function FoodWeightReviewTable(props) {
  const { headers, items, updatedWeights, handleUpdateWeights } = props;
  return (
    <div className="table table_food_weight">
      <TableHeaders headers={headers} />
      <div>
        {items.map((item, index) => {
          return (
            <FoodWeightRow
              key={index}
              item={item}
              updatedWeights={updatedWeights}
              handleUpdateWeights={handleUpdateWeights}
            />
          );
        })}
      </div>
    </div>
  );
}

export function GardenerTable({ changeApproval, users, toggleMore }) {
  return (
    <div className="table">
      <TableHeaders headers={gardenersHeaders} />
      <div>
        {users.map((user, index) => (
          <GardenerRow
            key={index}
            user={user}
            changeApproval={changeApproval}
            toggleMore={toggleMore}
          />
        ))}
      </div>
    </div>
  );
}

export function NonProfitTable({
  approvalStatus,
  changeApproval,
  headers,
  impersonate,
  selectedUsers,
  selectUser,
  sendEmail,
  sendEmailMultiple,
  sendEmailToGroup,
  toggleMore,
  userGroup,
  users
}) {
  const checkAll = () => selectUser(approvalStatus);
  const oneOrMoreSelected =
    Object.values(selectedUsers).filter(val => val).length > 0;
  const groupActions = () =>
    oneOrMoreSelected ? (
      <div className="table_header_cell_group">
        <div className="table_header_group_action_cell">
          <GroupAction
            users={selectedUsers}
            approved={approvalStatus}
            actionType={i18nstring(strings.APPROVED)}
            selectedUsers={selectedUsers}
            changeApproval={changeApproval}
          />
        </div>
        <div className="table_header_group_action_cell">
          <GroupMessageModal
            selectedUsers={selectedUsers}
            sendEmailMultiple={sendEmailMultiple}
            sendEmailToGroup={sendEmailToGroup}
            groupName={userGroup}
          />
        </div>
      </div>
    ) : (
      <div className="table_header_cell">Action</div>
    );
  return (
    <div className="table">
      <TableHeaders
        groupActions={groupActions}
        isChecked={selectedUsers[approvalStatus]}
        checkAll={checkAll}
        headers={headers}
      />
      <div>
        {users.map((user, index) => {
          const toggleUser = () => {
            selectUser(user.id);
          };
          const isSelectedUser =
            selectedUsers[user.id] || selectedUsers[approvalStatus];
          return (
            <NonProfitRow
              key={index}
              user={user}
              changeApproval={changeApproval}
              toggleMore={toggleMore}
              sendEmail={sendEmail}
              impersonate={impersonate}
              isChecked={isSelectedUser}
              selectUser={toggleUser}
              selectedUsers={selectedUsers}
              users={users}
            />
          );
        })}
      </div>
    </div>
  );
}
