import { currentLocale } from "../App";
import en_US from "./en-US.json";
import es_US from "./es-US.json";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const SupportedLocales = {
  DEFAULT: "en-US",
  EN_US: "en-US",
  ES_US: "es-US"
};

export const setCurrentLocale = async locale => {
  cookies.set("locale", locale, { path: "/" });
  window.location.reload();
  // AsyncStorage.setItem('locale', locale)
};

export const getCurrentLocale = async () => {
  return cookies.get("locale") || SupportedLocales.DEFAULT;
  return SupportedLocales.ES_US;
  // return (await AsyncStorage.getItem('locale')) || SupportedLocales.DEFAULT
};

const getLocaleString = (key, locale = currentLocale) => {
  let localizedString;

  switch (locale) {
    case SupportedLocales.EN_US:
      localizedString = en_US[key];
      break;
    case SupportedLocales.ES_US:
      localizedString = es_US[key];
      break;
    default:
      localizedString = en_US[key];
      break;
  }

  return localizedString || key;
};

export default getLocaleString;

export const strings = {
  LANGUAGE: "LANGUAGE",
  ENGLISH: "ENGLISH",
  SPANISH: "SPANISH",
  DASHBOARD: "DASHBOARD",
  DATA_EXPORT: "DATA_EXPORT",
  SELF_SERVICE: "SELF_SERVICE",
  FOOD_WEIGHT_REVIEW: "FOOD_WEIGHT_REVIEW",
  CHATS: "CHATS",
  COURIER_REVIEW: "COURIER_REVIEW",
  GARDENER_REVIEW: "GARDENER_REVIEW",
  TIME_WINDOWS: "TIME_WINDOWS",
  SETTINGS: "SETTINGS",
  SURVEY: "SURVEY",
  NON_PROFIT_REVIEW: "NON_PROFIT_REVIEW",
  SIDE_DASHBOARD: "SIDE_DASHBOARD",
  SIDE_COURIER: "SIDE_COURIER",
  SIDE_GARDENER: "SIDE_GARDENER",
  SIDE_TIME_WINDOWS: "SIDE_TIME_WINDOWS",
  SIDE_SETTINGS: "SIDE_SETTINGS",
  SIDE_SURVEY: "SIDE_SURVEY",
  SIDE_DATA_EXPORT: "SIDE_DATA_EXPORT",
  SIDE_NON_PROFIT: "SIDE_NON_PROFIT",
  SIDE_SELF_SERVICE: "SIDE_SELF_SERVICE",
  SIDE_FOOD_WEIGHT_REVIEW: "SIDE_FOOD_WEIGHT_REVIEW",
  SIDE_CHATS: "SIDE_CHATS",
  SIDE_HELP: "SIDE_HELP",
  SIDE_REPORT_BUG: "SIDE_REPORT_BUG",
  SIDE_REQUEST_FEATURE: "SIDE_REQUEST_FEATURE",
  POSTED: "POSTED",
  REQUESTED: "REQUESTED",
  CLAIMED: "CLAIMED",
  PICKED_UP: "PICKED_UP",
  DELIVERED: "DELIVERED",
  CANCELED: "CANCELED",
  EXPIRED: "EXPIRED",
  EDIT_WEIGHTS_MESSAGE: "EDIT_WEIGHTS_MESSAGE",
  UPDATE_WEIGHTS: "UPDATE_WEIGHTS",
  DayLabels: "DayLabels",
  TIME_RANGE: "TIME_RANGE",
  DAYS_ALLOWED: "DAYS_ALLOWED",
  SET_TIME_RANGES: "SET_TIME_RANGES",
  AM_TIME: "AM_TIME",
  PM_TIME: "PM_TIME",
  AM_LABEL: "AM_LABEL",
  PM_LABEL: "PM_LABEL",
  TIME_SCHEDULES_NOTE1: "TIME_SCHEDULES_NOTE1",
  TIME_SCHEDULES_NOTE2: "TIME_SCHEDULES_NOTE2",
  TIME_SCHEDULES_NOTE3: "TIME_SCHEDULES_NOTE3",
  TIME_SCHEDULES_PICKUP_NOTE: "TIME_SCHEDULES_PICKUP_NOTE", 
  DEFAULT_SCHEDULE: "DEFAULT_SCHEDULE",
  SCHEDULE: "SCHEDULE",
  SCHEDULE_NAME: "SCHEDULE_NAME",
  PICK_SCHEDULE: "PICK_SCHEDULE",
  ADD_EDIT_SCHEDULES: "ADD_EDIT_SCHEDULES",
  CREATE_SCHEDULE: "CREATE_SCHEDULE",
  DELETE_SCHEDULE: "DELETE_SCHEDULE",
  DELETE_SCHEDULE_CONFIRM_MESSAGE: "DELETE_SCHEDULE_CONFIRM_MESSAGE",
  TIME_ADDRESSES_NOTE1: "TIME_ADDRESSES_NOTE1",
  TIME_ADDRESSES_NOTE2: "TIME_ADDRESSES_NOTE2",
  TIME_ADDRESSES_NOTE3: "TIME_ADDRESSES_NOTE3",
  CONTACT_ADDRESS: "CONTACT_ADDRESS",
  ADDRESS: "ADDRESS",
  ADDRESS_NAME: "ADDRESS_NAME",
  ADD_EDIT_ADDRESSES: "ADD_EDIT_ADDRESSES",
  MANAGE_PICKUPS: "MANAGE_PICKUPS",
  CREATE_ADDRESS: "CREATE_ADDRESS",
  DELETE_ADDRESS: "DELETE_ADDRESS",
  PICK_ADDRESS: "PICK_ADDRESS",
  PICK_ZIP_CODE: "PICK_ZIP_CODE",
  DELETE_ADDRESS_CONFIRM_MESSAGE: "DELETE_ADDRESS_CONFIRM_MESSAGE",
  MANAGE_ZIPS_INFO_NOTE: "MANAGE_ZIPS_INFO_NOTE",
  MANAGE_ZIPS_INFO: "MANAGE_ZIPS_INFO",
  MANAGE_ZIPS_TABLE_HEADER_SCHEDULE: "MANAGE_ZIPS_TABLE_HEADER_SCHEDULE",
  MANAGE_ZIPS_TABLE_HEADER_ADDRESS: "MANAGE_ZIPS_TABLE_HEADER_ADDRESS",
  MANAGE_ZIPS_TABLE_HEADER_ZIPS: "MANAGE_ZIPS_TABLE_HEADER_ZIPS",
  MANAGE_ZIPS_TABLE_HEADER_EDIT_ZIPS: "MANAGE_ZIPS_TABLE_HEADER_EDIT_ZIPS",
  MANAGE_ZIPS: "MANAGE_ZIPS",
  MANAGE_SCHEDULES_ZIPS_MODAL_INFO: "MANAGE_SCHEDULES_ZIPS_MODAL_INFO",
  MANAGE_SCHEDULES_ZIPS_MODAL_DISABLE_INFO:
    "MANAGE_SCHEDULES_ZIPS_MODAL_DISABLE_INFO",
  MANAGE_SCHEDULES_ZIPS: "MANAGE_SCHEDULES_ZIPS",
  MANAGE_ADDRESSES_ZIPS_MODAL_INFO: "MANAGE_ADDRESSES_ZIPS_MODAL_INFO",
  MANAGE_ADDRESSES_ZIPS_MODAL_DISABLE_INFO:
    "MANAGE_ADDRESSES_ZIPS_MODAL_DISABLE_INFO",
  MANAGE_ADDRESSES_ZIPS: "MANAGE_ADDRESSES_ZIPS",
  MANAGE_ADDRESSES_NO_ZIPS: "MANAGE_ADDRESSES_NO_ZIPS",
  EXPORT: "EXPORT",
  NON_PROFIT_FILTER_LABELS: "NON_PROFIT_FILTER_LABELS",
  DEFAULT_ALL_USERS_FILTER_LABELS: "DEFAULT_ALL_USERS_FILTER_LABELS",
  ALL_USERS_FILTER_LABELS: "ALL_USERS_FILTER_LABELS",
  ALL_ITEMS_FILTER_LABELS: "ALL_ITEMS_FILTER_LABELS",
  DEFAULT_WAITING_USERS_LABELS: "DEFAULT_WAITING_USERS_LABELS",
  ITEM_FILTER_LABELS: "ITEM_FILTER_LABELS",
  DEFAULT_USER_FILTER_LABELS: "DEFAULT_USER_FILTER_LABELS",
  USER_FILTER_LABELS: "USER_FILTER_LABELS",
  DEFAULT_NOT_SUBSCRIBED_PRODUCERS_LABELS:
    "DEFAULT_NOT_SUBSCRIBED_PRODUCERS_LABELS",
  ALL_NOT_SUBSCRIBED_PRODUCERS_LABELS: "ALL_NOT_SUBSCRIBED_PRODUCERS_LABELS",
  DATA_TYPE: "DATA_TYPE",
  NON_PROFIT_EXPORT_DESCRIPTION: "NON_PROFIT_EXPORT_DESCRIPTION",
  NON_PROFIT_EXPORT_WARNING: "NON_PROFIT_EXPORT_WARNING",
  WHICH_EMAIL_EXPORT: "WHICH_EMAIL_EXPORT",
  EMPTY_EXPORT_FILTERS: "EMPTY_EXPORT_FILTERS",
  EXPORT_SUBMITTED: "EXPORT_SUBMITTED",
  GENERIC_ERROR: "GENERIC_ERROR",
  TWITTER_CANCEL: "TWITTER_CANCEL",
  EXISTS_ON_OTHER: "EXISTS_ON_OTHER",
  NETWORK_ERROR: "NETWORK_ERROR",
  USER_NOT_FOUND: "USER_NOT_FOUND",
  MISSING_TOKEN: "MISSING_TOKEN",
  LOCATION_UNSUPPORTED: "LOCATION_UNSUPPORTED",
  SUBSCRIPTION_UNAVAILABLE: "SUBSCRIPTION_UNAVAILABLE",
  NO_NON_PROFIT: "NO_NON_PROFIT",
  EMAIL_ALREADY_USED: "EMAIL_ALREADY_USED",
  INCORRECT_EMAIL_PASSWORD: "INCORRECT_EMAIL_PASSWORD",
  INCORRECT_PASSWORD: "INCORRECT_PASSWORD",
  INVALID_EIN: "INVALID_EIN",
  UNDEFINED: "UNDEFINED",
  ACCOUNT_CREATION: "ACCOUNT_CREATION",
  CONTACT_INFO: "CONTACT_INFO",
  DELIVERY_INSTRUCTIONS: "DELIVERY_INSTRUCTIONS",
  COURIER_APPROVAL: "COURIER_APPROVAL",
  SEND_EMAIL: "SEND_EMAIL",
  APPROVE: "APPROVE",
  DENY: "DENY",
  LOG_OUT: "LOG_OUT",
  USER_ACCOUNT: "USER_ACCOUNT",
  TAX_IDENTIFIER: "TAX_IDENTIFIER",
  NEXT: "NEXT",
  STOP: "STOP",
  SAVE_CHANGES: "SAVE_CHANGES",
  CONFIRM: "CONFIRM",
  CREATE: "CREATE",
  ADD_ZIP: "ADD_ZIP",
  ZIP_CODE_SECTION: "ZIP_CODE_SECTION",
  DELIVERY_INSTRUCTIONS_NOTE: "DELIVERY_INSTRUCTIONS_NOTE",
  OFFERS_DISABLED_INSTRUCTIONS_NOTE: "OFFERS_DISABLED_INSTRUCTIONS_NOTE",
  PASSWORD_SECTION: "PASSWORD_SECTION",
  NON_PROFIT_SECTION: "NON_PROFIT_SECTION",
  CONTACT_INFO_NOTE: "CONTACT_INFO_NOTE",
  PASSWORD_NOTE: "PASSWORD_NOTE",
  ENTER_ZIP_NOTE: "ENTER_ZIP_NOTE",
  ADDITIONAL_ZIPS: "ADDITIONAL_ZIPS",
  NON_PROFIT_NOTE: "NON_PROFIT_NOTE",
  EIN_NOTE: "EIN_NOTE",
  USERNAME: "USERNAME",
  EMAIL: "EMAIL",
  PASSWORD: "PASSWORD",
  PASSWORD_CONFIRMATION: "PASSWORD_CONFIRMATION",
  SUBMIT: "SUBMIT",
  REFRESH: "REFRESH",
  ZIP_CODE: "ZIP_CODE",
  NEW_ZIP_CODE: "NEW_ZIP_CODE",
  EIN: "EIN",
  SUB_ROLES: "SUB_ROLES",
  STREET_ADDRESS_1: "STREET_ADDRESS_1",
  STREET_ADDRESS_2: "STREET_ADDRESS_2",
  PHONE_NUMBER: "PHONE_NUMBER",
  CITY: "CITY",
  STATE: "STATE",
  ADDITIONAL_ZIP_1: "ADDITIONAL_ZIP_1",
  EMAIL_SUBJECT: "EMAIL_SUBJECT",
  EMAIL_BODY: "EMAIL_BODY",
  DELIVERY_INSTRUCTIONS_GARDENER: "DELIVERY_INSTRUCTIONS_GARDENER",
  OFFERS_DISABLED_INSTRUCTIONS_GARDENER: "OFFERS_DISABLED_INSTRUCTIONS_GARDENER",
  WARNING_LOCATION: "WARNING_LOCATION",
  WARNING_ZIP: "WARNING_ZIP",
  POSITIVE_ZIP: "POSITIVE_ZIP",
  POSITIVE_LOCATION: "POSITIVE_LOCATION",
  ERROR_ZIP: "ERROR_ZIP",
  ERROR_LOCATION: "ERROR_LOCATION",
  NON_PROFIT_NAME_TITLE: "NON_PROFIT_NAME_TITLE",
  NON_PROFIT_NAME: "NON_PROFIT_NAME",
  NON_PROFIT_NAME_INFO: "NON_PROFIT_NAME_INFO",
  NON_PROFIT_NAME_INFO_EX: "NON_PROFIT_NAME_INFO_EX",
  VIEWING_AS: "VIEWING_AS",
  ATTENTION: "ATTENTION",
  PICKUP_WINDOW: "PICKUP_WINDOW",
  TIME_SCHEDULING: "TIME_SCHEDULING",
  TIME_SCHEDULING_NOTE: "TIME_SCHEDULING_NOTE",
  TIME_SCHEDULING_NOTE_2: "TIME_SCHEDULING_NOTE_2",
  TIME_SCHEDULING_NOTE1: "TIME_SCHEDULING_NOTE1",
  TIME_SCHEDULING_NOTE2: "TIME_SCHEDULING_NOTE2",
  SUCCESS: "SUCCESS",
  WAITING_USER_SAVED: "WAITING_USER_SAVED",
  RESET_PASSWORD: "RESET_PASSWORD",
  NON_PROFIT: "NON_PROFIT",
  FFC_ADMIN: "FFC_ADMIN",
  COMMUNITY_CENTER: "COMMUNITY_CENTER",
  FOOD_BANK: "FOOD_BANK",
  FOOD_NONPROFIT: "FOOD_NONPROFIT",
  FOOD_RESCUE: "FOOD_RESCUE",
  SCHOOL: "SCHOOL",
  OTHER: "OTHER",
  DONATIONS: "DONATIONS",
  ACTIVE_DONATIONS: "ACTIVE_DONATIONS",
  ACTIVE_PENDING_DONATIONS: "ACTIVE_PENDING_DONATIONS",
  PAST_DONATIONS: "PAST_DONATIONS",
  PENDING_APPROVALS: "PENDING_APPROVALS",
  APPROVED_COURIERS: "APPROVED_COURIERS",
  APPROVED_GARDENERS: "APPROVED_GARDENERS",
  APPROVED_NONPROFITS: "APPROVED_NONPROFITS",
  MORE: "MORE",
  PENDING: "PENDING",
  REJECTED: "REJECTED",
  APPROVED: "APPROVED",
  TIME_INVALID: "TIME_INVALID",
  EMAIL_INVALID: "EMAIL_INVALID",
  EIN_INVALID: "EIN_INVALID",
  SUB_GROUP_INVALID: "SUB_GROUP_INVALID",
  PASSWORD_INVALID: "PASSWORD_INVALID",
  PASSWORD_CONFIRMATION_INVALID: "PASSWORD_CONFIRMATION_INVALID",
  USERNAME_INVALID: "USERNAME_INVALID",
  ZIP_INVALID: "ZIP_INVALID",
  STREET_ADDRESS_INVALID: "STREET_ADDRESS_INVALID",
  PHONE_NUMBER_INVALID: "PHONE_NUMBER_INVALID",
  CITY_INVALID: "CITY_INVALID",
  STATE_INVALID: "STATE_INVALID",
  BAD_EMAIL_FORMAT: "BAD_EMAIL_FORMAT",
  LOGIN_ADMIN: "LOGIN_ADMIN",
  LOGIN_APPROVAL_PENDING: "LOGIN_APPROVAL_PENDING",
  LOGIN_APPROVAL_REJECTED: "LOGIN_APPROVAL_REJECTED",
  LOGIN_INVALID_ROLE: "LOGIN_INVALID_ROLE",
  WRONG_ROLE: "WRONG_ROLE",
  GO_BACK: "GO_BACK",
  LOGIN: "LOGIN",
  SIGN_UP: "SIGN_UP",
  WELCOME_TO_FFC: "WELCOME_TO_FFC",
  SELF_SERVICE_DISABLED: "SELF_SERVICE_DISABLED",
  SELF_SERVICE_INSTRUCTION_1: "SELF_SERVICE_INSTRUCTION_1",
  SELF_SERVICE_INSTRUCTION_2: "SELF_SERVICE_INSTRUCTION_2",
  SELF_SERVICE_INSTRUCTION_DISABLED: "SELF_SERVICE_INSTRUCTION_DISABLED",
  SELF_SERVICE_INSTRUCTION_DROPOFF: "SELF_SERVICE_INSTRUCTION_DROPOFF",
  OFFERS: "OFFERS",
  OFFERS_INFO: "OFFERS_INFO",
  OFFERS_DROP_OFF: "OFFERS_DROP_OFF",
  OFFERS_DROP_OFF_INFO: "OFFERS_DROP_OFF_INFO",
  ENABLE_PICKUP: "ENABLE_PICKUP",
  OFFERS_PICKUP: "OFFERS_PICKUP",
  OFFERS_PICKUP_INFO: "OFFERS_PICKUP_INFO",
  PARTNERS: "PARTNERS",
  PARTNERS_INFO: "PARTNERS_INFO",
  PARTNERS_CSU: "PARTNERS_CSU",
  COLUMNS_TO_EXPORT: "COLUMNS_TO_EXPORT",
  START_DATE: "START_DATE",
  END_DATE: "END_DATE",
  CANCEL: "CANCEL",
  LOCATION_NAME: "LOCATION_NAME",
  ACCOUNT_ACTIONS: "ACCOUNT_ACTIONS",
  CHOOSE_FILE: "CHOOSE_FILE",
  SAVE: "SAVE",
  WEBSITE: "WEBSITE",
  NEW_FEATURES: "NEW_FEATURES",
  REMOVE_ZIPS_HELP: "REMOVE_ZIPS_HELP",
  REMOVE_ZIPS_CLICK_HERE: "REMOVE_ZIPS_CLICK_HERE",
  CHANGE_ZIPS: "CHANGE_ZIPS",
  SUBSCRIBED_ZIPS: "SUBSCRIBED_ZIPS",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  NEW_FEATURES_TITLE: "NEW_FEATURES_TITLE",
  NEW_FEATURES_BODY: "NEW_FEATURES_BODY",
  CLOSE: "CLOSE",
  RESET: "RESET",
  VIEW_AS: "VIEW_AS",
  ITEMS: "ITEMS",
  GARDENERS: "GARDENERS",
  COURIERS: "COURIERS",

  REPORTED_DONATIONS: "REPORTED_DONATIONS",
  REJECTION_REASON: "REJECTION_REASON"
};
