import { BaseApi } from "./Base";

class ItemApi extends BaseApi {
  getItems(role, active, dropOff, rejected=false) {
    return super.getApiRequest("/items", { role, active, dropOff, rejected });
  }

  reviseItem(id, item) {
    return super.putApiRequest(`/items/${id}/revise`, item);
  }

  updateItem(id, action, data) {
    return super.putApiRequest(`/items/${id}`, { action, data });
  }
}

const itemApi = new ItemApi();

export default itemApi;
