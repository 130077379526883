import Immutable from "seamless-immutable";

import {
  SHOW_LOADING,
  HIDE_LOADING,
  SHOW_ERROR,
  UPDATE_ITEMS,
  UPDATE_ITEM_HISTORY
} from "./Types";
import api from "../../api";
import { Roles } from "../../Shared/Constants/Roles";
// Models
// import Item from "../../model/Item";
// Transforms
import { replaceInItems } from "../../transforms/ItemTransforms";

export function getItems(dropOffOnly, silent = false, rejected = undefined) {
  return async function (dispatch, getState) {
    try {
      if (!silent) dispatch({ type: SHOW_LOADING });

      const result = await api.item.getItems(
        Roles.RECIPIENT,
        true,
        dropOffOnly,
        rejected
      );

      if (result.success) {
        const sortedItems = result.data.sort(
          (a, b) => a.datePosted - b.datePosted
        );

        dispatch({ type: UPDATE_ITEMS, items: sortedItems });
      } else {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.error(e);
    } finally {
      if (!silent) dispatch({ type: HIDE_LOADING });
    }
  };
}

export function reviseItems(items) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SHOW_LOADING });

      let { history } = getState().marketplace;
      history = Immutable.asMutable(history);

      const results = await Promise.all(
        items.map(item => {
          try {
            if(Immutable.isImmutable(item)){
              var item = Immutable.asMutable(item);
              item.producerContact = Immutable.isImmutable(item.producerContact) ? Immutable.asMutable(item.producerContact) : item.producerContact;
              item.courierContact = Immutable.isImmutable(item.courierContact) ? Immutable.asMutable(item.courierContact) : item.courierContact;
            }
            delete item?.producerContact?.email;
            delete item?.courierContact?.email;
          } catch (error) {}
          return api.item.reviseItem(item.id, item);
        })
      );

      results.forEach(result => {
        if (!result.success) {
          // don't merge failed item updates
          console.warn(`failed to revise item ${result.data?.id}`);
          return;
        }
        history = replaceInItems(history, result.data);
      });

      const sortedHistory = history.sort((a, b) => b.datePosted - a.datePosted);

      dispatch({ type: UPDATE_ITEM_HISTORY, history: sortedHistory });
    } catch (e) {
      console.error(e);
      dispatch({ type: SHOW_ERROR, message: e });
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

export function getHistory(silent = false) {
  return async function (dispatch, getState) {
    try {
      if (!silent) dispatch({ type: SHOW_LOADING });
      dispatch({ type: UPDATE_ITEM_HISTORY, history: [] });

      const result = await api.item.getItems(Roles.RECIPIENT, false);

      if (result.success) {
        const sortedItems = result.data.sort(
          (a, b) => b.datePosted - a.datePosted
        );

        dispatch({ type: UPDATE_ITEM_HISTORY, history: sortedItems });
      } else {
        dispatch({ type: SHOW_ERROR, message: result.error });
      }
    } catch (e) {
      console.error(e);
    } finally {
      if (!silent) dispatch({ type: HIDE_LOADING });
    }
  };
}
