import React, { Component } from "react";
import { connect } from "react-redux";
// Actions
import { getHistory, getItems, reviseItems } from "../../redux/actions/Marketplace";
// Components
import { FoodWeightReviewTable } from "../organisms/Table";
import { Button } from "../atoms/Button";
// Constants
import i18nstring, { strings } from "../../i18n";

// Themes
import "./PageStyles.scss";
import { foodWeightHeaders } from "../../model/Table";
import SelfService from "./SelfService";

class FoodWeightReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updatedWeights: [],
      updatedItemsObj: {}
    };
  }

  componentDidMount() {
    if (!this.props.itemsFetched) {
      this.props.getHistoryItems();
    }

    const tempWeights = {};
    this.props.items.forEach(item => {
      const { weight, pickUpWeight } = item;
      const latestWeight = pickUpWeight ? pickUpWeight : weight;
      tempWeights[item.id] = latestWeight;
    });
    this.setState({ updatedItemsObj: tempWeights });
  }

  refreshItemsAndHistory = () => {
    this.props.getHistoryItems();
    this.setState({ updatedWeights: {} });
  };

  handleUpdateWeight = item => {
    const { updatedWeights, updatedItemsObj } = this.state;
    const { id, updatedWeight } = item;
    const tempObj = {
      ...updatedWeights
    };

    if (parseFloat(updatedItemsObj[id]) === parseFloat(updatedWeight)) {
      delete tempObj[id];
    } else if (parseFloat(updatedWeight) >= 0) {
      tempObj[id] = parseFloat(updatedWeight);
    }
    this.setState({ updatedWeights: tempObj });
  };

  sendWeightUpdates = () => {
    const { updatedWeights } = this.state;

    const itemsToSend = this.props.items
      .filter(item => updatedWeights[item.id] !== undefined)
      .map(item => {
        return { ...item, pickUpWeight: updatedWeights[item.id] };
      });

    this.props.attemptChangeWeights(itemsToSend);
    this.setState({ updatedWeights: {} });
  };

  getDonations = () => {
    this.refreshItemsAndHistory();
    this.props.attemptGetItems();
  }

  render() {
    const { updatedWeights } = this.state;

    const areWeightsUpdating = Object.keys(updatedWeights).length > 0;

    return (
      <div className="main_container_section donations_container">
        <div className="donations_refresh_container">
            <Button onClick={this.getDonations}>{i18nstring(strings.REFRESH)}</Button>
          </div>
        <SelfService />
        <div className="instructions_button_container">
          <div className="refresh_container">
            {areWeightsUpdating ? (
              <div className="update_weights_container">
                <Button onClick={this.sendWeightUpdates}>
                  {i18nstring(strings.UPDATE_WEIGHTS)}
                </Button>
              </div>
            ) : null}
          </div>
        </div>
        <div className="main_container_table">
          <div className="table_title">
            {i18nstring(strings.PAST_DONATIONS)}
          </div>
          <FoodWeightReviewTable
            approvalStatus={true}
            headers={foodWeightHeaders}
            items={this.props.items}
            updatedWeights={updatedWeights}
            handleUpdateWeights={this.handleUpdateWeight}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  items: state.marketplace.history,
  itemsFetched: state.marketplace.historyFetched
});

const mapDispatchToProps = dispatch => {
  return {
    attemptGetItems: () => dispatch(getItems(undefined,true, true)),
    attemptChangeWeights: newItems => dispatch(reviseItems(newItems)),
    getHistoryItems: () => dispatch(getHistory())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FoodWeightReview);
